var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "phoneBox", staticStyle: { "overflow-y": "auto" } },
    [
      _c(
        "div",
        {
          staticClass: "phone",
          staticStyle: { position: "relative" },
          attrs: { id: "preview" },
        },
        [
          _c(
            "div",
            { staticClass: "phoneStartBox" },
            [
              _c("el-image", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: { src: require("@/assets/images/phoneStart.png") },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "navBar x-f" },
            [
              _c("i", {
                staticClass: "x-f-start",
                staticStyle: { "font-size": "25px", width: "90px" },
              }),
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.headline)),
              ]),
              _c("el-image", {
                staticClass: "capsule x-f-end",
                attrs: { src: require("@/assets/images/capsule.png") },
              }),
            ],
            1
          ),
          _vm._l(
            _vm.$store.state.template.fixationTempData,
            function (item, index) {
              return _c("div", { key: index })
            }
          ),
          _c(
            "div",
            { staticClass: "main", staticStyle: { position: "relative" } },
            [
              _c(
                "draggable",
                {
                  attrs: {
                    list: _vm.$store.state.template.tempData,
                    move: _vm.draggableOnMove,
                    filter: ".unmover",
                  },
                },
                [
                  _vm._l(
                    _vm.$store.state.template.tempData,
                    function (item, index) {
                      return [
                        item && item.name
                          ? _c(
                              "div",
                              {
                                key: index + "." + item.name,
                                staticClass: "item",
                                class: {
                                  tempBorder:
                                    _vm.$store.state.template.selectIndex ==
                                    index,
                                  enterBorder:
                                    _vm.enterItem == index && index > 0,
                                  unmover:
                                    item.name === "dibudaohang" ||
                                    item.name === "navigation",
                                  tipsBox: item.name === "tanchuang",
                                  kefuBox: item.name === "kefu",
                                  sosuoBox:
                                    item.name === "sousuo" &&
                                    _vm.$store.state.template.tempData.findIndex(
                                      function (e) {
                                        return e && e.name == "sousuo"
                                      }
                                    ) != "-1"
                                      ? _vm.$store.state.template.tempData[
                                          _vm.$store.state.template.tempData.findIndex(
                                            function (e) {
                                              return e && e.name == "sousuo"
                                            }
                                          )
                                        ].style.isFixed
                                      : false,
                                },
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.enter(index)
                                  },
                                  mouseleave: _vm.leave,
                                  click: function ($event) {
                                    return _vm.tempClick(index)
                                  },
                                },
                              },
                              [
                                _vm.$store.state.template.selectIndex == index
                                  ? _c(
                                      "div",
                                      { staticClass: "cardBtns cursorP" },
                                      [
                                        !["navigation"].includes(item.name)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "cardBtns-item",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.changeItem(
                                                      index,
                                                      -1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-up",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        !["navigation"].includes(item.name)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "cardBtns-item",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.changeItem(
                                                      index,
                                                      1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "cardBtns-item",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteItem(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(item.name, {
                                  tag: "component",
                                  attrs: { keys: "" + new Date(), data: item },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }