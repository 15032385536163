var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { style: { padding: _vm.type == 1 ? "12px 16px" : "" } }, [
      _c(
        "div",
        {
          staticClass: "x-ac",
          style: {
            backgroundColor: _vm.data.contentStyle.bgColor || "#ffffff",
            boxShadow: _vm.type == 1 ? "0 8px 24px rgba(0, 0, 0, .2)" : "",
            borderRadius: _vm.type == 1 ? "42px" : "",
            height: _vm.type == 1 ? "60px" : "55px",
          },
        },
        _vm._l(_vm.data.contentStyle.navList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "y-c",
              style: { width: 100 / _vm.data.contentStyle.navNumber + "%" },
            },
            [
              _vm.data.contentStyle.type != 3 && _vm.data.contentStyle.isCustom
                ? _c("img", {
                    style: {
                      width: _vm.type == 2 && item.type == 2 ? "65px" : "24px",
                      height: _vm.type == 2 && item.type == 2 ? "65px" : "24px",
                      marginBottom:
                        _vm.type == 2 && item.type == 2 ? "17px" : "2px",
                    },
                    attrs: { src: index == 0 ? item.checkIcon : item.icon },
                  })
                : _vm._e(),
              _vm.data.contentStyle.type != 3 && !_vm.data.contentStyle.isCustom
                ? _c("iconParkALi", {
                    style: {
                      marginBottom:
                        _vm.type == 2 && item.type == 2 ? "17px" : "2px",
                    },
                    attrs: {
                      name: item.iconName,
                      size: _vm.type == 2 && item.type == 2 ? "65px" : "24px",
                      color:
                        index == 0 || (_vm.type == 2 && item.type == 2)
                          ? "#fe5933"
                          : "#a5a5a5",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: { "font-size": "12px" },
                  style: {
                    color:
                      index == 0
                        ? _vm.data.contentStyle.checkTextColor || "#fe5933"
                        : _vm.data.contentStyle.textColor || "#333",
                  },
                },
                [_vm._v(" " + _vm._s(item.label || item.name))]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }